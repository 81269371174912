import React from 'react'
import axios from 'axios'
import * as storage from '../utils/storage'
import EventComponent from './event'

class ComingEvents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      events: [],
    }
  }

  componentDidMount() {
    axios
      .post(
        `${process.env.API_BASE_URL}/graphql`,
        {
          query: `query {
          me {
            id
            comingEvents(first: 50, soonestFirst: true) {
              edges {
                node {
                  id
                  name
                  url
                  status
                  starts
                  ends
                  guestStatus
                  attended
                }
              }
            }
          }
        }`,
        },
        {
          headers: {
            Authorization: 'Bearer ' + storage.getItem('access_token'),
          },
        }
      )
      .then(res => {
        const myEvents = res.data.data.me.comingEvents.edges.map(
          edge => edge.node
        )
        const me = res.data.data.me.id

        axios
          .post(`${process.env.API_BASE_URL}/graphql`, {
            query: `query {
          events(first: 50, soonestFirst: true, status: "live", excludePast: true, user: ${me}) {
            edges {
              node {
                id
                name
                url
                status
                starts
                ends
              }
            }
          }
        }`,
          })
          .then(res => {
            const events = res.data.data.events.edges.map(edge => {
              let myEvent = myEvents.find(item => item.id === edge.node.id)
              if (!myEvent) return edge.node
              return { ...edge.node, ...myEvent }
            })

            this.setState({ events })
          })
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  } // componentDidMount

  render() {
    const { events } = this.state
    return (
      <React.Fragment>
        {events.map((event, i) => (
          <EventComponent key={i} event={event} />
        ))}
      </React.Fragment>
    )
  }
}

export default ComingEvents
