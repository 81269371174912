import React from 'react'
import axios from 'axios'
import * as storage from '../utils/storage'
import EventComponent from './event'


class PastEvents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      events: [],
    }
  }

  getHoursPastDate = hoursBefore => {
    return new Date(new Date() - 1000 * 60 * 60 * hoursBefore)
  }

  componentDidMount() {
    axios
      .post(
        `${process.env.API_BASE_URL}/graphql`,
        {
          query: `query {
          me {
              id
              pastEvents(first: 8) {
                edges {
                  node {
                    id
                    name
                    url
                    status
                    starts
                    ends
                    guestStatus
                    attended
                  }
                }
              }
          }
        }`,
        },
        {
          headers: {
            Authorization: 'Bearer ' + storage.getItem('access_token'),
          },
        }
      )
      .then(res => {
        const pastEvents = res.data.data.me.pastEvents.edges.map(
          edge => edge.node
        )
        const me = res.data.data.me.id

        axios
          .post(`${process.env.API_BASE_URL}/graphql`, {
            query: `query {
          events(first: 3, status: "completed", excludePast: false, user: ${me}) {
            edges {
              node {
                id
                name
                url
                status
                starts
                ends
              }
            }
          }
        }`,
          })
          .then(res => {
            const events = res.data.data.events.edges
              .map(edge => {
                let pastEvent = pastEvents.find(
                  item => item.id === edge.node.id
                )
                if (!pastEvent) return edge.node
                return { ...edge.node, ...pastEvent }
              })
              .filter(row => {
                return this.getHoursPastDate(48) <= new Date(row.ends)
              })
            this.setState({ events })
          })
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  } // componentDidMount

  render() {
    const { events } = this.state
    return (
      <React.Fragment>
        {events.map((event, i) => (
          <EventComponent key={i} event={event} isPastEvent />
        ))}
      </React.Fragment>
    )
  }
}

export default PastEvents
