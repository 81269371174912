import React from 'react'
import Layout from '../components/layout'
import ComingEvents from '../components/coming-events'
import PastEvents from '../components/past-events'
// Once granted
//         access into the group you attended, simply select anyone who you would
//         like to see again. If they have also selected you, you'll be able to see
//         each other's contact details and photo the following day.
export default ({ data }) => (
  <Layout>
    <h2 className="has-text-centered title is-2">Events</h2>
    <center>
      <p style={{ marginTop: '54px', marginBottom: '34px' }}>
        Find your event below then tap the 'Request Access' button. <br/>
        You will be able to select your matches after the event has ended.
      </p>
    </center>
    <table className="table past-events eventstable">
      <thead>
        <tr>
          <th align="center" className="time">Date</th>
          <th align="center" className="etitle">Event</th>
          <th align="center" className="action">Action</th>
        </tr>
      </thead>
      <tbody>
        <PastEvents />
        <ComingEvents />
      </tbody>
    </table>
  </Layout>
)
